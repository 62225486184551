import { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Form, Input, Select, Button, message, Spin, Space } from "antd";
import { getRedirectUrl, submitLeadWithX } from "../api/lead";
import LeadForm from "../component/LeadForm";
// import FingerprintJS from 'fingerprintjs2';
import { generateUID, getBrowserFingerprint } from "../utils/common";
import { useLocation } from "react-router-dom";

interface PageProps {
  from?: string; // 如果不传，默认为path name
  category?: string; // 默认 lead
}

const HouseKeepingPage: React.FC<PageProps> = ({ from = "", category = "housekeeping" }) => {
  const location = useLocation();

  const [redirectToZFB, setRedirectToZFB] = useState(true);
  const [showLeadForm, setShowLeadForm] = useState(false);

  function getUID() {
    // 尝试从 localStorage 获取 uid
    let uid = localStorage.getItem("uid");
    // 如果 uid 不存在，则生成一个新的 uid
    if (!uid) {
      uid = generateUID();
      localStorage.setItem("uid", uid);
    }

    return uid;
  }

  const getFrom = () => {
    if (from) return from;
    const pathname = location.pathname;
    const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);
    return lastSegment;
  };

  useEffect(() => {
    const uid = getUID();
    // 浏览器指纹，每次都计算
    getBrowserFingerprint().then((fingerprint) => {
      // console.log("新建浏览器指纹", fingerprint);
      checkRedirect(uid, fingerprint || "");
    });
  }, []);

  const checkRedirect = useCallback(async (uid: string, p: string) => {
    try {
      const data = await getRedirectUrl(category, getFrom(), uid, p);
      // console.log("服务器返回数据", data);
      if (data.code === 1000) {
        if (!data.data?.hit && data.data?.redirectUrl) {
          // 该ip不在审核名单内 跳转
          setRedirectToZFB(true);
          window.location.href = data.data.redirectUrl;
          return;
        }
      } else {
        // 找不到页面，或审核模式
      }
    } catch (error) {
      console.error("Error during redirection:", error);
    }
    // console.log("默认不跳转");
    document.title = "联系我们";
    setRedirectToZFB(false);
  }, []);

  const LeadForm = lazy(() => import("../component/LeadForm"));

  return (
    // 表单页的格式不同
    <div className={`landing-container w-full flex justify-center h-screen ${redirectToZFB && "items-center"}}`}>
      {/* 默认跳转中... */}
      <Spin spinning={redirectToZFB}>
        {!redirectToZFB &&
          (showLeadForm ? (
            // <LeadForm from={FROM} />
            <Suspense fallback={<div>加载中...</div>}>
              <LeadForm from={getFrom()} />
            </Suspense>
          ) : (
            <Space direction="vertical" className={"block w-full max-w-md relative"}>
              <div>
                <img src={"./images/housekeeping-1.jpg"} alt="首页" />
                <img src={"./images/housekeeping-2.jpg"} alt="首页" />
                <img src={"./images/housekeeping-3.jpg"} alt="首页" />
                <img src={"./images/housekeeping-4.jpg"} alt="首页" />
                <img src={"./images/housekeeping-5.jpg"} alt="首页" />
              </div>
              <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-80 mb-2">
                <Button className="block w-full" type="primary" size="large" onClick={() => setShowLeadForm(true)}>
                  联系我们
                </Button>
              </div>
            </Space>
          ))}
      </Spin>
    </div>
  );
};

export default HouseKeepingPage;
